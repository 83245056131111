import { cn } from '../../utils/cn';

export const AssessmentIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      version='1.1'
      viewBox='15 15 70 70'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('w-6 h-6 fill-current', className)}
    >
      <path d='m25.469 43.129c2.5 2.5 6.5508 2.5 9.0508 0l12.301-12.301c1.5625-1.5625 1.5625-4.0938 0-5.6562-1.5625-1.5664-4.0977-1.5664-5.6602 0l-11.16 11.168-5.1719-5.1719v0.003906c-1.5625-1.5664-4.0938-1.5664-5.6562 0-1.5664 1.5625-1.5664 4.0938 0 5.6562z' />
      <path d='m78 38h-25c-2.2109 0-4 1.7891-4 4s1.7891 4 4 4h25c2.2109 0 4-1.7891 4-4s-1.7891-4-4-4z' />
      <path d='m78 21h-18c-2.2109 0-4 1.7891-4 4s1.7891 4 4 4h18c2.2109 0 4-1.7891 4-4s-1.7891-4-4-4z' />
      <path d='m46 55h-24c-2.2109 0-4 1.7891-4 4s1.7891 4 4 4h24c2.2109 0 4-1.7891 4-4s-1.7891-4-4-4z' />
      <path d='m46 72h-24c-2.2109 0-4 1.7891-4 4s1.7891 4 4 4h24c2.2109 0 4-1.7891 4-4s-1.7891-4-4-4z' />
      <path d='m81.371 56.82c-0.65625-1.0156-1.7383-1.6797-2.9414-1.8047-1.2031-0.125-2.3945 0.29688-3.25 1.1562l-5.1797 5.1797-5.1797-5.1797c-0.83984-0.83984-2.0078-1.2656-3.1875-1.1602-1.1836 0.10547-2.2578 0.73047-2.9336 1.707-1.0898 1.6836-0.82422 3.9023 0.62891 5.2812l5 5-5.1016 5.1016h0.003907c-1.375 1.3359-1.625 3.457-0.60156 5.0781 0.65625 1.0156 1.7383 1.6797 2.9414 1.8047 1.2031 0.125 2.3945-0.29688 3.25-1.1562l5.1797-5.1797 5.1797 5.1797c0.83984 0.83984 2.0078 1.2656 3.1875 1.1602 1.1836-0.10547 2.2578-0.73047 2.9336-1.707 1.0898-1.6797 0.83203-3.8984-0.62109-5.2812l-5-5 5.1016-5.1016c1.3711-1.3398 1.6172-3.457 0.58984-5.0781z' />
    </svg>
  );
};
